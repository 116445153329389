<template>
  <div class="home" v-loading="loading">
    <br><br>
    <el-row justify="center">
      <el-col :sm="24" :md="18" :lg="18">
        <Slider/>
      </el-col>
    </el-row>
    <br><br>
    <el-row justify="center">
      <el-col :sm="24" :md="18" :lg="18">
        <div v-if="categoryProducts.length" v-for="(category, index) in categoryProducts" :key="index">
          <el-card class="my-10 border-0" v-if="category.products.length" shadow="never">
            <div class="d-flex align-items-center justify-content-between">
              <h4>{{ category.name }} </h4>
              <el-button @click="viewDetails(category.slug)">View Details</el-button>
            </div>
            <ProductsComponent :products="category.products"/>
          </el-card>
        </div>
      </el-col>
    </el-row>
    <el-row justify="center">
      <el-col :sm="24" :md="18" :lg="18">
        <Products/>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import Slider from '../components/Slider.vue';
// eslint-disable-next-line import/extensions
import Brands from '../components/Brands';
// import axios from 'axios';
// eslint-disable-next-line import/extensions
import Products from '@/components/Products';
import ProductsComponent from '@/components/ProductsComponent';
import axios from 'axios';

export default {
  name: 'Home',
  title: 'Home',
  components: {
    Products,
    Slider,
    Brands,
    ProductsComponent,
  },
  data() {
    return {
      categoryProducts: [],
      loading: false,
    };
  },
  created() {
    this.getCategoryProduct();
  },
  methods: {
    getCategoryProduct() {
      this.loading = true;
      axios.get(`/api/web/category/products`)
          .then(res => {
            this.categoryProducts = res.data.data;
            this.loading = false;
          });
    },
    viewDetails(slug) {
      return this.$router.replace({
        path: 'products',
        query: { category: slug }
      });
    },
  }
};
</script>
