<template>
  <div class="block text-center">
    <div class="demo-image__lazy">
      <router-link to="/products">
        <el-image v-if="setting.setting" :src="setting.setting.banner.full_url" lazy/>
        <el-image v-else :src="require('@/assets/images/slider.jpeg')" lazy/>
      </router-link>
    </div>
    <!--    <el-carousel height="400px">-->
    <!--      <el-carousel-item v-for="item in 4" :key="item">-->
    <!--        <h3 class="small justify-center" text="2xl">{{ item }}</h3>-->
    <!--      </el-carousel-item>-->
    <!--    </el-carousel>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Slider',
  computed: {
    ...mapGetters({
      setting: 'setting/get_setting',
    })
  }
};
</script>

<style scoped>
.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.demo-image__lazy {
  height: auto;
  overflow-y: auto;
}

.demo-image__lazy .el-image {
  display: block;
  min-height: 200px;
  margin-bottom: 10px;
}

.demo-image__lazy .el-image:last-child {
  margin-bottom: 0;
}
</style>
