<template>
  <div class="common-section-padding">
    <div class="products">
      <el-row :gutter="20" justify="center">
        <el-col :xs="12" :sm="8" :md="6" :lg="6" :xl="6" class="mb-5" v-for="(product, index) in products" :key="index">
          <div class="product">
            <router-link :to="`/product/${product.slug}`">
              <img :src="productImage(product.thumbnail_image)" v-if="product.thumbnail_image"
                   :alt="product.name" class="product_image" :fit="fit" lazy>
              <el-empty description="no image" :image-size="100" v-else/>
            </router-link>
            <div class="product-content">
              <div>
                <router-link :to="`/product/${product.slug}`">{{ product.name }}</router-link>
              </div>
              <div>
                <p v-if="product.discount">৳ {{ getDiscountPrice(product.price, product.discount) }}
                </p>
                <p v-if="product.discount">
                  <del class="text-muted">৳ {{ product.price }}</del>
                  <span class="discount-percentage">-{{ product.discount }}%</span>
                </p>
                <p v-else> ৳ {{ product.price }} </p>
              </div>
              <br>
<!--              <el-button type="success" round @click="addProductToCart(product)">Add to Cart</el-button>-->
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductsComponent',
  props: {
    products: {},
  },
  data(){
    return {
      fit: 'contain',
    }
  },
  methods: {
    getDiscountPrice(price, discount) {
      // eslint-disable-next-line radix
      if (discount != 'null') {
        const sum = (parseFloat(price) * parseFloat(discount)) / 100;
        return parseFloat(price) - sum;
      }
      return price;
    },
    actualPrice(product) {
      if (product.discount != 'null') {
        return product.price - product.discount;
      }
      return product.price;
    },
    addProductToCart(product) {
      this.$store.dispatch('shopingCart/addProductToCart', product);
    },
    productImage(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    }
  },
};
</script>

<style scoped>
a {
  color: #000;
}

.product {
  text-align: center;
  border: 2px solid #eb4960;
  padding: 0px 0px 15px 0px;
  border-radius: 7px;
}

p {
  margin: 0;
  padding: 0;
}

.text-muted {
  color: #d2d1d1;
}

.product_image {
  height: 200px;
  width: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  /*height: 100px;*/
}

/*.product_image:hover {*/
/*  transform: scale(1.5);*/
/*}*/
.product-content {
  margin-top: 20px;
}
</style>
