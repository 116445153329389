<template>
    <el-row justify="center" class="mt-50">
      <el-col :span="18">
        <el-row :gutter="50">
          <el-col :sm="24" :md="8" :lg="8">
            <el-card class="box-card" shadow="never"> 
              <div class="text-center">
                <el-avatar v-if="authUser.image" :size="40" class="user_avatar">
                  <img
                      :src="userPhoto(authUser.image)" alt="Bd Royel Culture"
                  />
                </el-avatar>
                <el-avatar v-else :size="40" class="user_avatar">
                  <img
                      src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" alt="Bd Royel Culture"
                  />
                </el-avatar>
                <br />
                <br />
                <h4> {{ authUser ? authUser.name : 'Guest' }}</h4>
                <p> {{ authUser ? authUser.email : '' }}</p>
              </div>
              <br />
              <hr class="mt-5">
              <div class="user-menu">
                <el-menu
                  default-active="2"
                  class="el-menu-vertical-demo "
                  
                >
                  
                  <el-menu-item index="4">
                    <el-icon><Grid /></el-icon>
                    <span>Dashboard</span>
                  </el-menu-item>

                  <el-menu-item index="4">
                    <el-icon><Tickets /></el-icon>
                    <span>Purchase History</span>
                  </el-menu-item>

                  <el-menu-item index="4">
                    <el-icon><User /></el-icon>
                    <span>Manage Profile</span>
                  </el-menu-item>

                  <el-menu-item index="4">
                    <el-icon><RemoveFilled /></el-icon>
                    <span>Logout</span>
                  </el-menu-item>

                </el-menu>
              </div>
            </el-card>
          </el-col>
          <el-col :span="16">
            <el-row :gutter="20">
              <el-col :sm="18" :md="8" :lg="8">
                <el-card shadow="never" class="text-center">
                  <h4>Total Order</h4>
                  <h3 class="mt-5">49</h3>
                </el-card>
              </el-col>
              <el-col :sm="18" :md="8" :lg="8">
                <el-card shadow="never" class="text-center">
                  <h4>Delivery Order</h4>
                  <h3 class="mt-5">49</h3>
                </el-card>
              </el-col>
              <el-col :sm="18" :md="8" :lg="8">
                <el-card shadow="never" class="text-center">
                  <h4>Cancel Order</h4>
                  <h3 class="mt-5">49</h3>
                </el-card>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
      
    </el-row>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'Dashboard',
  computed: {
    ...mapGetters('auth', ['authUser', 'loggedIn']),
  },
};
</script>

<style scoped>
.mt-50 {
  margin-top: 50px;
}
</style>
<style>
.user-menu .el-menu {
  border-right:  0px !important;
}
</style>
