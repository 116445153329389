<template>
  <div>
    <el-form :model="form" label-position="top" v-if="products.length" :rules="rules" ref="form">
      <el-row justify="center">
        <el-col :sm="24" :md="16" :lg="16">
          <el-card class="mt-40">
            <h3>BILLING & SHIPPING</h3>
            <br>
            <el-row :gutter="20">
              <el-col :sm="24" :md="8" :lg="8">
                <el-form-item label="Name" prop="name">
                  <el-input v-model="form.name" />
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="8" :lg="8">
                <el-form-item label="Phone number" prop="phone_number">
                  <el-input v-model="form.phone_number" />
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="8" :lg="8">
                <el-form-item label="Email(Optional)">
                  <el-input v-model="form.email" />
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="12" :lg="12">
                <el-form-item label="Address" prop="address">
                  <el-input v-model="form.address" type="textarea" />
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="12" :lg="12">
                <el-form-item label="Special notes">
                  <el-input v-model="form.notes" type="textarea" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="30">
              <el-col :sm="24" :md="12" :lg="12">
                <el-form-item label="Choose Shipping Method">
                  <el-radio-group v-model="form.delivery_fee" class="d-block">
                    <el-radio :label="setting.setting.outside_dhaka_delivery_fee" class="d-block">Delivery Outside
                      Dhaka: <b> {{ setting.setting.outside_dhaka_delivery_fee }} TK</b></el-radio>
                    <el-radio :label="setting.setting.inside_dhaka_delivery_fee"> Delivery Inside Dhaka: <b> {{
      setting.setting.inside_dhaka_delivery_fee }} TK</b></el-radio>

                    <el-radio :label="setting.setting.sub_dhaka_fee"> Delivery Sub Dhaka : <b> {{
      setting.setting.sub_dhaka_fee }} TK</b></el-radio>

                  </el-radio-group>


                  <el-alert title="Sub Dhaka" type="info" :closable="false" 
                    description="Gazipur- Board Bazar, Mawna, Gazipur-Joydebpur, Kaliganj, Savar, Dhamrai, Kaliganj, Bhulta-Gawsia, Narayangonj, Gazaria, Sonargaon, Siddhirganj, Bandar" />
                </el-form-item>


              </el-col>
              <el-col :sm="24" :md="12" :lg="12">

                <el-form-item label="Choose Payment Method">
                  <el-radio-group v-model="form.payment_type" class="d-block">
                    <el-radio label="cod" class="d-block">Cash on Delivery</el-radio>
                    <el-radio label="bKash">bKash</el-radio>
                  </el-radio-group>
                  <el-input v-model="form.bKash_number" v-if="form.payment_type == 'bKash'" placeholder="Enter number" />
                </el-form-item>
              </el-col>
            </el-row>
            <div class="text-center">
              <h4>Total Amount: <b>{{ total + parseInt(this.form.delivery_fee) }} TK</b></h4>
              <br>
              <el-button @click="confirmOrder('form')" class="brand-bg color_white" round>PLACE ORDER</el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>

    </el-form>
    <el-empty description="No product found in cart.Please continue shoping" v-else />

  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'Checkout',
  data() {
    return {
      form: {
        name: '',
        phone_number: '',
        email: '',
        address: '',
        notes: '',
        delivery_fee: 100,
        payment: 'cod',
        bKash_number: '',
        payment_type: 'cod',
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Please input  name',
          },
        ],
        phone_number: [
          {
            required: true,
            min: 11,
            message: 'Please input  phone number',
          },
        ],
        address: [
          {
            required: true,
            message: 'Address file is required',
          },
        ],
      },
    };
  },
  created() {
    this.form.delivery_fee = this.setting.setting.outside_dhaka_delivery_fee;
  },
  computed: {
    ...mapGetters({
      setting: 'setting/get_setting'
    }),
    products() {
      return this.$store.state.shopingCart.cart;
    },
    total() {
      return this.$store.getters['shopingCart/cartTotal'];
    }
  },
  methods: {
    confirmOrder(formEl) {
      this.$refs[formEl].validate((valid) => {
        if (valid) {
          let items = [];
          if (this.products) {
            this.products.map(item => {
              items.push({
                product_id: item.id,
                qty: item.quantity,
                color_id: item.color_id,
                attribute_id: item.attribute_id,
                attribute_value_id: item.attribute_value_id,
                price: item.details.price,
              });
            });
          }

          let total_amount = parseInt(this.total) +  parseInt(this.form.delivery_fee);

          let order = {
            'name': this.form.name,
            'phone_number': this.form.phone_number,
            'email': this.form.email,
            'billing_address': this.form.address,
            'delivery_address': this.form.address,
            'notes': this.form.notes,
            'delivery_fee': this.form.delivery_fee,
            'total_amount': total_amount,
            'bKash_num': this.form.bKash_number,
            'payment_type': this.form.payment_type,
            'products': items,
          };

          axios.post(`api/web/make/order`, order)
            .then(res => {
              if (res.data.status_code == 200) {
                this.$store.dispatch('shopingCart/emptyCart');
                this.$notify({
                  title: 'Success',
                  message: 'Order Complete Successfully',
                  type: 'success',
                  duration: 2000,
                });
                this.$router.push({
                  path: '/thank/you/message',
                  query: { id: res.data.data.invoice_no }
                });

              } else {
                this.$notify({
                  title: 'Failed',
                  message: res.data.message,
                  type: 'danger',
                  duration: 2000,
                });
              }
            });
        } else {
          return true;
        }
      });
    }
  }
};
</script>

<style scoped></style>
