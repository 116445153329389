export const OrderMixins = {
  data() {
    return {
      order: {},
      order_details: {},
    };
  },
  methods: {
    buyNowProduct(product) {
      this.$store.dispatch('shopingCart/addProductToCart', product);
      this.$router.push('/checkout');
    },
  },
};
