<template>
  <div class="mt-20">
    <el-tree
        ref="treeRef"
        class="filter-tree"
        :data="categories"
        default-expand-all
        :props="structure"
        @node-click="handleNodeClick"
    />
  </div>
</template>

<script>

export default {
  name: 'Categories',
  mounted() {
    this.$store.dispatch('category/getCategory');
  },
  data() {
    return {
      structure: {
        label: 'name',
        children: 'grandchildren',
      },
    };
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    categories() {
      // eslint-disable-next-line no-unused-expressions
      // return this.$store.state.products.products;
      return this.$store.state.category.categories;
    },
  },
  methods: {
    handleNodeClick(data) {
      this.$router.replace({
        path: 'products',
        query: { category: data.slug }
      });
    },
  },
};
</script>

<style scoped>
a {
  color: #000;
}

ul li {
  line-height: 30px;
}
</style>
