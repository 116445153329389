<template>
  <div class="login" v-loading="loading">
    <div>
      <el-row justify="center">
        <el-col :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
          <el-alert :title="error_msg" type="error" v-if="error_msg"/>
          <br>
          <el-card>
            <el-form
                ref="ruleFormRef"
                :model="form"
                status-icon
                label-position="top">

              <el-form-item label="Full name" prop="fullname">
                <el-input v-model="form.name"></el-input>
              </el-form-item>

              <el-form-item label="Email" prop="email">
                <el-input v-model="form.email"></el-input>
              </el-form-item>

              <el-form-item label="Password" prop="pass">
                <el-input v-model="form.password" type="password" autocomplete="off"/>
              </el-form-item>

              <el-form-item>
                <el-button @click="submitForm" type="primary"
                >Submit
                </el-button>
                <el-button @click="resetForm()">Reset</el-button>
              </el-form-item>

            </el-form>
            <div class="d-flex justify-content-between">
              <p>You have already account please
                <router-link to="/login">Login</router-link>
              </p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Register',
  title: 'Registration',
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
      },
      rules: [],
      loading: false,
      error_msg: '',
    };
  },
  methods: {
    submitForm() {
      axios.post(`/api/register`, this.form)
          .then(res => {
           
            if (res.data.status_code == 200) {
              this.$notify({
                title: 'Success',
                message: 'Successfully Account Created',
                type: 'success',
                duration: 2000,
              });
              this.$router.push('/login');
            } else {
              this.$notify({
                title: 'Failed',
                message: 'Failed. Please try  again',
                type: 'error',
                duration: 2000,
              });
            }
          })
          .catch(err => {
            console.log('er', err);
            this.$notify({
              title: 'Failed',
              message: 'Failed Please try  again',
              type: 'error',
              duration: 2000,
            });
          });
    },
    resetForm() {
      this.form = {};
    }
  }

};
</script>

<style scoped>

</style>
