<template>
  <div class="mt-40">
    <el-row justify="center" :gutter="20">
      <el-col :span="12">
        <el-card class="text-center py-10">
          <h3>Thank you for your order</h3>
          <p>We are back as soon as possible</p>
          <h2>Tracking ID: {{ $route.query.id }}</h2>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  name: 'ThankYouMessageWithInvoice',
  title: 'Thank you for your order.',
};
</script>

<style scoped>

</style>
