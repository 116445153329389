<template>
  <div class="common-section-padding" style="background: #d3dce6">
    <el-row justify="center" :gutter="10">
      <el-col :sm="24" :md="24" :lg="24">
        <el-row justify="space-between">
          <el-col :sm="14" :md="14" :lg="14">
            <el-input v-model="filter.search" @input="getProducts" class="w-50 m-2" size="large" placeholder="Search Products, Categories..."
              prefix-icon="Search" />
          </el-col>
          <el-col :="8" :md="8" :lg="8">
            <div>
                    <el-select @node-click="handleNodeClick" class="m-2" v-model="filter.selectable"
                      placeholder="Default sorting" size="large">
                      <el-option v-for="item in sorting_options" :key="item.value" :label="item.label"
                        :value="item.value" />
                    </el-select>

                  </div>
          </el-col>
          </el-row>
        <el-row justify="center">
          <!-- <el-col :sm="24" :md="8" :lg="8">
            <div class="product-sidebar">
              <h4>Product categories</h4>
              <Category />
              <br>
              <h4>Brands</h4>
              <br>
              <div class="custom-card">
                <el-checkbox-group>
                  <el-checkbox class="d-block" v-for="brand in brands" :key="brand.id" :label="brand.name">{{
      brand.name
    }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </el-col> -->
          <el-col :sm="24" :md="24" :lg="24">
            
            <div>
              <br>
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <el-tag v-if="$route.query.category" class="mx-1" closable type="danger">
                      {{ $route.query.category }}
                    </el-tag>
                  </div>
                  
                </div>
              </div>
              <br>
            </div>
            <div class="">
              <div class="products" v-loading="loading">
                <el-row :gutter="10">
                  <el-col v-if="products.length" :xs="12" :sm="12" :md="8" :lg="6" :xl="6" class="mb-10"
                    v-for="(product, index) in products" :key="index">
                    <el-card shadow="hover" class="border-0 product_height">
                      <div class="product">
                        <!--              <div class="discount-area">-->
                        <!--                {{ product.discount }}/ OFF-->
                        <!--              </div>-->
                        <router-link :to="`/product/${product.slug}`">
                          <img :src="productImage(product.thumbnail_image)" v-if="product.thumbnail_image"
                            :alt="product.name" class="product_image" :fit="fit" lazy>
                          <el-empty v-else :image-size="100" />
                        </router-link>
                        <div class="product-content">
                          <div>
                            <router-link :to="`/product/${product.slug}`">{{ product.name }}</router-link>
                          </div>
                          <!-- pricing -->
                          <div>
                            <p v-if="product.discount > 0">৳ {{ getDiscountPrice(product.price, product.discount) }}
                            </p>

                            <p v-if="product.discount > 0">
                              <del class="text-muted">৳ {{ product.price }}</del>
                              <span class="discount-percentage">-{{ product.discount }}%</span>
                            </p>
                            <p v-else> ৳ {{ product.price }} </p>
                          </div>
                          <br>
                          <!-- <el-button type="success" round @click="addProductToCart(product)">Add to Cart</el-button> -->
                        </div>

                      </div>
                    </el-card>
                  </el-col>
                  <div class="text-center" v-else>
                    <el-empty description="No product found" />
                  </div>
                </el-row>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Products from '@/components/Products';
import Category from '@/components/Categories';
import axios from 'axios';

export default {
  name: 'Product',
  title: 'Product',
  data() {
    return {
      brands: [],
      searchQuery: '',
      sorting_options: [
        {
          label: 'Sort by popularity',
          value: 'popularity',
        },
        {
          label: 'Sort by newness',
          value: 'new',
        },
        {
          label: 'Sort by price: low to high',
          value: 'price_low',
        },
        {
          label: 'Sort by price: high to low',
          value: 'price_heigh',
        },
      ],
      filter: {
        selectable: '',
        search: '',
      },
      products: [],
      loading: false,
      fit: 'fit',
    };
  },
  components: {
    Products,
    Category,
  },
  created() {
    this.getBrands();
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getBrands() {
      axios.get(`/api/web/brands`)
        .then(res => {
          this.brands = res.data.data;
        });
    },
    handleNodeClick(data) {
      this.$router.replace({
        path: 'products',
        query: { search: this.filter.selectable }
      });
    },
    updateSearch() {
      this.$router.push({ path: `/search=${this.filter.search}` });
    },
    getProducts() {
      console.log('dd', this.$route.query);
      this.loading = true;
      let url = '';
      if(this.filter.search) {
        this.$router.push({ query: { q: '' } });
        this.$route.query.q = null;
      }
      if (this.$route.query.q) {
        url = `/api/web/products${this.$route.query.q ? '?q=' + this.$route.query.q : ''}`;
      }else if (this.filter.search){
        url = `/api/web/products${this.filter.search ? '?q=' + this.filter.search : ''}`;

      } else {
        url = `/api/web/products${this.$route.query.category ? '?category=' + this.$route.query.category : ''}`;
      }

      axios.get(url)
        .then(res => {
          this.products = res.data.data;
          this.loading = false;
        });
    },
    getDiscountPrice(price, discount) {
      // eslint-disable-next-line radix
      if (discount != 'null') {
        const sum = (parseFloat(price) * parseFloat(discount)) / 100;
        return parseFloat(price) - sum;
      }
      return price;
    },
    actualPrice(product) {
      if (product.discount != 'null') {
        return product.price - product.discount;
      }
      return product.price;
    },
    addProductToCart(product) {
      this.$store.dispatch('shopingCart/addProductToCart', product);
    },
    productImage(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
    // updateUrlParams(query) {
    //   // Assuming you want to update the URL params
    //   this.$router.push({ query: { q: query } });
    // },
  },
  // watch: {
  //   searchQuery(newVal) {
  //     this.updateUrlParams(newVal);
  //   },
  // },

};
</script>

<style scoped>
a {
  color: #000;
}

.product {
  text-align: center;
}

p {
  margin: 0;
  padding: 0;
}

.text-muted {
  color: #d2d1d1;
}

.product_image {
  height: 200px;
  width: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  /*height: 100px;*/
}

.product-content {
  margin-top: 20px;
}
</style>
