<template>
  <!--  <el-container>-->
  <div class="login" v-loading="loading">
    <div>
      <el-row :gutter="10" justify="center">
        <el-col :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
          <el-alert :title="error_msg" type="error" v-if="error_msg"/>
          <br>
          <el-card>

            <el-form
                ref="ruleFormRef"
                :model="form"
                status-icon
                label-position="top"
                label-width="120px"
                class="demo-ruleForm">

              <el-form-item label="Email" prop="email">
                <el-input v-model="form.email"></el-input>
              </el-form-item>


              <el-form-item>
                <el-button @click="submitForm" type="primary"
                >Submit
                </el-button>
                <el-button @click="resetForm()">Reset</el-button>
              </el-form-item>

            </el-form>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
  <!--  </el-container>-->
</template>

<script>
export default {
  name: 'ForgetPassword',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      rules: [],
      loading: false,
      error_msg: '',
    };
  },
  methods: {
    submitForm() {

    },
    resetForm() {
      this.form = {};
    }
  }
};
</script>

<style scoped>

</style>
