<template>
  <div>

    <el-row justify="center" class="mt-40" v-if="cartProducts.length">
      <el-col :xs="24" :sm="24" :md="16" :lg="16">
        <el-card v-for="(cartProduct,index) in cartProducts" :key="index" class="my-5 border-0" shadow="hover">
          <CartProduct :product="cartProduct"/>
        </el-card>
      </el-col>
    </el-row>
    <div v-else>
      <el-empty description="Empty Cart"/>
    </div>

  </div>
</template>

<script>
import CartProduct from '@/components/product/CartProduct';
import axios from 'axios';

export default {
  name: 'ShopingCart',

  components: {
    CartProduct,
  },

  computed: {
    cartProducts() {
      return this.$store.state.shopingCart.cart;
    }
  },
};
</script>

<style scoped>

</style>
