<template>
  <div class="login" v-loading="loading">
    <div>
      <el-row justify="center">
        <el-col :xs="24" :sm="12" :md="10" :lg="8" :xl="6">
          <br>
          <el-alert :title="error_msg" type="error" v-if="error_msg"/>
          <br>
          <el-card>

            <el-form
                ref="ruleFormRef"
                :model="form"
                status-icon
                label-position="top"
                label-width="120px"
                class="demo-ruleForm">

              <el-form-item label="Email" prop="email">
                <el-input v-model="form.email"></el-input>
              </el-form-item>

              <el-form-item label="Password" prop="pass">
                <el-input v-model="form.password" type="password" autocomplete="off"/>
              </el-form-item>

              <el-form-item>
                <el-button @click="submitForm" type="primary"
                >Submit
                </el-button>
                <el-button @click="resetForm()">Reset</el-button>
              </el-form-item>

            </el-form>
            <div class="d-flex justify-content-between">
              <p>Are you new
                <router-link to="/register">Register</router-link>
              </p>
              <p>Are you new
                <router-link to="/forget/password">Forgot Password</router-link>
              </p>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Login',
  title: 'Login',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      rules: [],
      loading: false,
      error_msg: '',
    };
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions('auth', ['login','logout']),
    submitForm() {
      this.loading = true;
      if (this.form) {
        this.login(this.form)
            .then(res => {
              if (res.data.status_code == 400) {
                this.loading = false;
                this.form.userid = '';
                return this.error_msg = res.data.message;
              }
              localStorage.setItem('api_token', res.data.user.api_token);
              this.loading = false;
              return this.$router.push('/');
            })
            .catch(err => {
              this.loading = false;
              console.log('err', err);
              return this.error_msg = "Something Wrong";
            });
      }
    },
    resetForm() {
      this.form = {};
    }
  }
};
</script>

<style scoped>

</style>
