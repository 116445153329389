<template>
  <div class="">

    <div class="products" v-loading="loading">
      <el-row :gutter="10">
        <el-col v-if="products.length" :xs="12" :sm="12" :md="8" :lg="6" :xl="6" class="mb-10"
                v-for="(product, index) in products"
                :key="index">
          <el-card shadow="hover" class="border-0 product_height">
            <div class="product">
              <!--              <div class="discount-area">-->
              <!--                {{ product.discount }}/ OFF-->
              <!--              </div>-->
              <router-link :to="`/product/${product.slug}`">
                <img :src="productImage(product.thumbnail_image)" v-if="product.thumbnail_image"
                     :alt="product.name" class="product_image" :fit="fit" lazy>
                <el-empty v-else :image-size="100"/>
              </router-link>
              <div class="product-content">
                <div>
                  <router-link :to="`/product/${product.slug}`">{{ product.name }}</router-link>
                </div>
                <!-- pricing -->
                <div>
                  <p class="price" v-if="product.discount > 0">৳ {{ getDiscountPrice(product.price, product.discount) }}
                  </p>

                  <p v-if="product.discount > 0">
                    <del class="text-muted">৳  {{ product.price }}</del>
                    <span class="discount-percentage">-{{ product.discount }}%</span>
                  </p>
                  <p class="price" v-else> ৳  {{ product?.price }} </p>
                </div>
                <!-- <el-button type="success" round @click="addProductToCart(product)">Add to Cart</el-button> -->
              </div>

            </div>
          </el-card>
        </el-col>
        <div class="text-center" v-else>
          <el-empty description="No product found"/>
        </div>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Products',
  data() {
    return {
      fit: 'contain',
      products: [],
      loading: false,
    };
  },
  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.loading = true;
      let url = `/api/web/products${this.$route.query.category ? '?category=' + this.$route.query.category : ''}`;

      axios.get(url)
          .then(res => {
            this.products = res.data.data;
            this.loading = false;
          });
    },
    getDiscountPrice(price, discount) {
      // eslint-disable-next-line radix
      if (discount != 'null') {
        const sum = (parseFloat(price) * parseFloat(discount)) / 100;
        return parseFloat(price) - sum;
      }
      return price;
    },
    actualPrice(product) {
      if (product.discount != 'null') {
        return product.price - product.discount;
      }
      return product.price;
    },
    addProductToCart(product) {
      this.$store.dispatch('shopingCart/addProductToCart', product);
    },
    productImage(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    }
  },
};
</script>

<style scoped>
a {
  color: #000;
}

.product {
  text-align: center;
}

p {
  margin: 0;
  padding: 0;
}

.text-muted {
  color: #d2d1d1;
}

.product_image {
  height: 200px;
  width: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 5px;
  overflow: hidden;
  /*height: 100px;*/
}

.product-content {
  margin-top: 20px;
}

.price {
  font-weight: bold;
  margin-top: 10px;
}
</style>
