<template>
<div class="product-gallery">
    <div class="main-image">
      <!-- Display the main image -->
      <img v-if="images[currentIndex]" :src="productImage(images[currentIndex])" alt="Product Image" lazy>
      <el-image v-else>
      
        <template #error>
          <div class="image-slot">
            <el-icon><icon-picture /></el-icon>
          </div>
        </template>
      </el-image>
    </div>
    <div class="thumbnail-container">
      <button class="arrow left" @click="moveLeft">
        <el-icon>
          <ArrowLeftBold/>
        </el-icon>
      </button>

      <!-- Iterate over images and display thumbnails -->
      <div class="thumbnails" ref="thumbnailsContainer">
        <img v-for="(image, index) in images" :key="index" class="thumbnail"
             :src="productImage(image)"
             @click="selectThumbnailForVariant(index)" lazy>
      </div>
      
      <button class="arrow right" @click="moveRight">
        <el-icon>
          <ArrowRightBold/>
        </el-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { Picture as IconPicture } from '@element-plus/icons-vue'

export default {
  name: 'ProductGallery',
  props: {
    images:[],
  },
  data() {
    return {
      currentIndex: 0,
      is_variant_images: false,
      is_images: true,
    };
  },
 
  methods: {
    
    selectThumbnailForVariant(index) {
      this.currentIndex = index;
    },
    moveLeft() {
      const thumbnailsContainer = this.$refs.thumbnailsContainer;
      const scrollAmount = thumbnailsContainer.clientWidth;
      thumbnailsContainer.scrollLeft -= scrollAmount;
    },
    moveRight() {
      const thumbnailsContainer = this.$refs.thumbnailsContainer;
      const scrollAmount = thumbnailsContainer.clientWidth;
      thumbnailsContainer.scrollLeft += scrollAmount;
    },
    productImage(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
  },
};
</script>

<style scoped>
.product-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image img {
  max-width: 100%;
}

.thumbnail-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  overflow-x: hidden;
}

.thumbnails {
  display: flex;
  margin: 0 10px;
  transition: transform 0.3s ease-out;
  overflow: scroll;
}

.thumbnail {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  cursor: pointer;
}

.arrow {
  background-color: #fff;
  border: none;
  font-size: 24px;
  font-weight: bold;
  padding: 5px 10px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
}


</style>


